<template>
  <div class="flex items-center justify-center h-screen">
    <v-progress-circular
      indeterminate
      color="primary"
      size="64"
    ></v-progress-circular>
  </div>
</template>

<script setup>
import { useSocialConnection } from "@/composables/store/useSocialConnection";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const socialConnectionStore = useSocialConnection();
// const { showSnackbar } = useSnackbar();

onMounted(async () => {
  try {
    const code = route.query.code;
    const state = route.query.state;

    if (!code || !state) {
      throw new Error("Missing required parameters");
    }

    const response = await socialConnectionStore.finishLinkedInAuth(
      code,
      state
    );

    if (response.success) {
      // Redirect based on status
      if (response.status === "onboarding") {
        router.push("/onboarding");
      } else {
        router.push("/dashboard");
      }
    }
  } catch (error) {
    console.error("Error in LinkedIn callback:", error);
    // showSnackbar({
    //   text: "Failed to connect LinkedIn account",
    //   color: "error",
    // });
    // router.push("/dashboard");
  }
});

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});
</script>
