<script setup>
import PublicStepper from "@/@core/components/PublicStepper.vue";
import OnboardConfirmationCard from "@/components/cards/OnboardConfirmationCard.vue";
import OnboardDetailsCard from "@/components/OnboardDetailsCard.vue";
import { useAgencyStore } from "@/composables/store/useAgencyStore";
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useRequestAccessStore } from "@/composables/store/useRequestAccessStore";
import OnboardRequestAccessTable from "@/views/demos/forms/tables/data-table/OnboardRequestAccessTable.vue";
import atrLogo from "@images/logos/atr.png";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const currentStep = ref(0);
const isLoading = ref(true);
const isStepperEditable = ref(false);

const agencyStore = useAgencyStore();
const requestAccessStore = useRequestAccessStore();
const { companyDetails } = storeToRefs(agencyStore);

const name = ref("");
const email = ref("");
const agencyId = ref(null);
const clientEmail = ref(null);
const clientName = ref(null);
const socialMedia = ref([]);

// Combine loading states
const isPageLoading = computed(
  () => requestAccessStore.isRequestAccessLoading || isLoading.value
);

// Optimize token verification and data fetching
const checkTokenAndSetStep = async () => {
  try {
    isLoading.value = true;

    const token = route.query.token;

    // Parallelize API calls
    const [tokenExists, verifiedToken] = await Promise.all([
      requestAccessStore.checkOnboardTokenExist(token),
      agencyStore.verifyPublicLinkToken(token),
    ]);

    if (tokenExists?.length) {
      isStepperEditable.value = true;

      const {
        agencyId: decodedAgencyId,
        clientEmail: decodedEmail,
        clientName: decodedName,
        socialMedia: decodedSocialMedia,
      } = verifiedToken?.decoded || {};

      // Set all values at once
      Object.assign({
        agencyId: decodedAgencyId,
        clientEmail: decodedEmail,
        clientName: decodedName,
        socialMedia: decodedSocialMedia,
        name: decodedName || "",
        email: decodedEmail || "",
      });
    } else {
      currentStep.value = 0;
    }
  } catch (error) {
    console.error("Error during token verification", error);
    currentStep.value = 0;
  } finally {
    isLoading.value = false;
  }
};

// Initialize the check when the component loads
const init = async () => {
  // Add variable to Local Storage, service_module: connect_agency
  if (authStore.user?.user_type === "client_service") {
    localStorage.setItem("service_module", "connect_client");
  }
  await checkTokenAndSetStep();
};

init();

// Use a more efficient watcher
watch(
  () => currentStep.value,
  async (newStep) => {
    if (newStep === 1 || newStep === 2) {
      await checkTokenAndSetStep();
    }
  },
  { flush: "post" }
); // Defer until after updates

// Cache frequently accessed data
const cachedCompanyDetails = computed(() => companyDetails.value);

const checkoutSteps = [
  {
    title: "Onboard Details",
    icon: "tabler-square-number-1",
  },
  {
    title: "Grant Access",
    icon: "tabler-square-number-2",
  },
  {
    title: "Confirmation",
    icon: "tabler-square-number-3",
  },
];

const checkoutData = ref({
  cartItems: [],
  promoCode: "",
  orderAmount: 0,
  deliveryAddress: "home",
  deliverySpeed: "free",
  deliveryCharges: 0,
  addresses: [],
});
</script>

<template>
  <div class="fixed left-4 z-10 p-4 bg-white" data-test-id="atr-logo-container">
    <VImg width="195px" :src="atrLogo" data-test-id="atr-logo" />
  </div>

  <VOverlay
    v-show="isPageLoading"
    :opacity="0.8"
    absolute
    data-test-id="loading-overlay"
  >
    <VProgressCircular indeterminate size="64" data-test-id="loading-spinner" />
  </VOverlay>

  <div class="flex flex-col min-h-screen" data-test-id="connect-page-container">
    <Suspense>
      <VContainer class="my-auto mx-auto flex-1" data-test-id="main-container">
        <div class="checkout-card" data-test-id="checkout-card">
          <VCard
            class="pa-0 ma-0"
            elevation="0"
            :loading="isPageLoading"
            data-test-id="main-card"
          >
            <VCardText>
              <PublicStepper
                v-model:current-step="currentStep"
                class="checkout-stepper"
                :items="checkoutSteps"
                :direction="
                  $vuetify.display.mdAndUp ? 'horizontal' : 'vertical'
                "
                :disabled-steps="!isStepperEditable"
                data-test-id="onboard-stepper"
              />
            </VCardText>
            <VDivider />
            <VCardText class="pa-0 ma-0">
              <VWindow
                v-model="currentStep"
                class="disable-tab-transition"
                :touch="false"
                data-test-id="onboard-window"
              >
                <VWindowItem data-test-id="onboard-details-step">
                  <OnboardDetailsCard
                    v-model:current-step="currentStep"
                    data-test-id="onboard-details-card"
                  />
                </VWindowItem>
                <VWindowItem data-test-id="request-access-step">
                  <OnboardRequestAccessTable
                    v-model:current-step="currentStep"
                    data-test-id="request-access-table"
                  />
                </VWindowItem>
                <VWindowItem data-test-id="confirmation-step">
                  <OnboardConfirmationCard
                    v-model:current-step="currentStep"
                    data-test-id="confirmation-card"
                  />
                </VWindowItem>
              </VWindow>
            </VCardText>
          </VCard>
        </div>
      </VContainer>
    </Suspense>

    <footer
      class="w-full mt-10 p-6 bg-gray-100 border-t border-gray-300"
      data-test-id="page-footer"
    >
      <div
        class="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between"
        data-test-id="footer-content"
      >
        <div class="mb-4 md:mb-0" data-test-id="footer-logo">
          <VImg width="150px" :src="atrLogo" />
        </div>

        <div class="flex space-x-6" data-test-id="footer-links">
          <a
            href="https://www.attherate.ai/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
            class="text-gray-700 hover:text-blue-600 transition"
            data-test-id="terms-link"
            >Terms and Conditions</a
          >
          <a
            href="https://www.attherate.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            class="text-gray-700 hover:text-blue-600 transition"
            data-test-id="privacy-link"
            >Privacy Policy</a
          >
          <a
            href="https://www.attherate.ai/security-q-a"
            target="_blank"
            rel="noopener noreferrer"
            class="text-gray-700 hover:text-blue-600 transition"
            data-test-id="security-link"
            >Security & QA</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped>
.checkout-stepper {
  .stepper-icon-step {
    .step-wrapper + svg {
      margin-inline: 3.5rem !important;
    }
  }
}

.checkout-card {
  margin-block: 4rem 3rem;
}

@media (max-width: 960px) and (min-width: 600px) {
  .checkout-page {
    .v-container {
      padding-inline: 2rem !important;
    }
  }
}

@media (max-width: 600px) {
  .checkout-card {
    margin-block-start: 6rem;
  }
}
</style>
