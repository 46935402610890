import { useAuthStore } from "@/composables/store/useAuthStore";
import { useErrorStore } from "@/composables/store/useErrorStore";
import axios from "axios";
import { ERROR_MESSAGES, LOCALSTORAGE } from "./constants";

// Hasura Axios Instance
const hasuraInstance = axios.create({
  baseURL: import.meta.env.VITE_HASURA_BASE_URL, // Replace with your Hasura API base URL
  headers: {
    "Content-Type": "application/json",
    ...{ "x-hasura-admin-secret": import.meta.env.VITE_HASURA_ADMIN_SECRET },

    // Add any other default headers specific to Hasura
  },
});

// Express Axios Instance
const apiInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL, // Replace with your Express API base URL
  headers: {
    "Content-Type": "application/json",

    // Add any other default headers specific to Express
  },
});

// Request Interceptor
hasuraInstance.interceptors.request.use(
  (config) => {
    // Add authorization token to headers if available
    const token = localStorage.getItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN);
    if (token) {
      if (import.meta.env.VITE_HASURA_ADMIN_SECRET) return config;
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.log(error);

    // Handle request error here
    return Promise.reject(error);
  }
);

// Request Interceptor
apiInstance.interceptors.request.use(
  (config) => {
    // Add authorization token to headers if available
    const token = localStorage.getItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.log(error);

    // Handle request error here
    return Promise.reject(error);
  }
);

// Response Interceptor
hasuraInstance.interceptors.response.use(
  (response) => {
    // if one of the array (response.data.errors) is not null, throw error
    // loop through response.data.errors
    // check if extensions.code === "invalid-jwt"
    // if true, logout user
    // else, throw error
    if (response.data?.errors) {
      response.data.errors.forEach((error) => {
        if (error.extensions.code === "invalid-jwt") {
          useErrorStore().setErrorMessage("Token expired");
          useAuthStore().logout();
          window.location.reload();
        } else {
          useErrorStore().setErrorMessage(error.message);
        }
      });
    }

    // Any status code within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes outside the range of 2xx cause this function to trigger
    // Add your error handling logic here
    if (error.response && error.response?.status === 401) {
      // Handle 401 Unauthorized response, for example
      // You might want to refresh token or redirect to login page
    } else if (error?.response?.status === 500) {
      useErrorStore().setErrorMessage("Server error");
    } else if (error.response?.formFields) {
      // Join all form field errors into a single string
      const errorMessages = error.response.formFields
        .map((field) => field.error)
        .join(", ");

      useErrorStore().setErrorMessage(errorMessages);
    } else {
      useErrorStore().setErrorMessage(response.data.status);
    }

    return Promise.reject(error);
  }
);

// Response Interceptor
apiInstance.interceptors.response.use(
  (response) => {
    if (
      response.status === 200 &&
      response.data.status === "WRONG_CREDENTIALS_ERROR"
    ) {
      useErrorStore().setErrorMessage(ERROR_MESSAGES[response.data.status]);
      throw response.data;
    }
    if (response.status === 200 && response.data.status === "FIELD_ERROR") {
      const errorMessages = response.data.formFields
        .map((field) => field.error)
        .join(", ");

      useErrorStore().setErrorMessage(errorMessages);
      throw response.data;
    }
    if (response.status === 200) {
      return response;
    }
    if (response.data?.formFields) {
      // Join all form field errors into a single string
      const errorMessages = response.data?.formFields
        .map((field) => field.error)
        .join(", ");

      useErrorStore().setErrorMessage(errorMessages);
      throw response.data;
    } else if (ERROR_MESSAGES[response.data.status]) {
      useErrorStore().setErrorMessage(ERROR_MESSAGES[response.data.status]);
      throw response.data;
    } else if (response?.statusText !== "OK") {
      // Check if key exists in ERROR MESSAGES object
      useErrorStore().setErrorMessage(response?.data?.status);
      throw response.data;
    }

    // Any status code within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes outside the range of 2xx cause this function to trigger
    // Add your error handling logic here
    console.log(error);
    if (error.response && error.response?.status === 401) {
      // Handle 401 Unauthorized response, for example
      // You might want to refresh token or redirect to login page
    } else if (error.code === "ERR_BAD_REQUEST") {
      useErrorStore().setErrorMessage(
        error.response?.data?.message || error.message
      );
    } else if (error.code === "ERR_NETWORK") {
      useErrorStore().setErrorMessage(ERROR_MESSAGES.SERVER_ERROR);
    } else if (error.response?.formFields) {
      // Join all form field errors into a single string
      const errorMessages = error.response.formFields
        .map((field) => field.error)
        .join(", ");

      useErrorStore().setErrorMessage(errorMessages);
    } else if (error?.response?.data?.error) {
      useErrorStore().setErrorMessage(error.response.data.error);
    } else if (error.response.message) {
      useErrorStore().setErrorMessage(error.response.message);
    } else {
      useErrorStore().setErrorMessage(
        error.message || error.response?.data?.error
      );
    }

    return Promise.reject(error);
  }
);

const sendGraphQLRequest = async (query, variables = {}) => {
  try {
    const response = await hasuraInstance.post("/v1/graphql", {
      query,
      variables,
    });

    return response.data;
  } catch (error) {
    console.error("GraphQL request error:", error.response?.data || error);
    throw error;
  }
};

export { apiInstance, hasuraInstance, sendGraphQLRequest };
