<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useErrorStore } from "@/composables/store/useErrorStore";
import { useSocialConnection } from "@/composables/store/useSocialConnection";
import { onMounted } from "vue";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const route = useRoute();
const router = useRouter();
const socialConnection = useSocialConnection();
const authStore = useAuthStore();
// Decode this %7B%22agency_id%22%3A%22%22%7D" to {"agency_id":""}
//
const agencyId = JSON.parse(decodeURIComponent(route.query?.state))?.agency_id;
const clientId = JSON.parse(decodeURIComponent(route.query?.state))?.client_id;
const status = JSON.parse(decodeURIComponent(route.query?.state))?.status;
const urlId = JSON.parse(decodeURIComponent(route.query?.state))?.url_id;
const urlToken = JSON.parse(decodeURIComponent(route.query?.state))?.url_token;

const code = route.query?.code;

console.log("code", code);
console.log("shop", route.query?.shop);

const shop = route.query?.shop;

const state = route.query?.state;
console.log("route.query", agencyId);

// Sample Reference:https://attherate-ikmal.myshopify.com/admin/oauth/authorize?client_id=62dc9d1818273ca2b379ba782014e3b5&scope=read_products,read_orders,read_customers&redirect_uri=http://localhost:5173/shopify/callback&state=%7B%22agency_id%22%3A%22bd82ad17-1e7b-4547-a39c-820c5041db31%22%2C%22client_id%22%3A%229e673f09-03b0-4381-b281-2b232f9e4382%22%7D"
const saveShopifyOAuthToken = async () => {
  try {
    if (!route?.query?.code) {
      useErrorStore().setError("Invalid Shopify OAuth code");
      return;
    }
    const response = await socialConnection.finishShopifyOAuth(
      shop,
      code,
      state
    );
    console.log("status", status);
    setTimeout(() => {
      router.push("/onboarding");
      // if (response.success) {
      //   // Redirect based on status
      //   if (response.status === "onboarding") {
      //     router.push("/onboarding");
      //   } else {
      //     router.push("/dashboard");
      //   }
      // }
      // if (status === "public") {
      //   router.push({
      //     name: "pages-connect-id",
      //     params: {
      //       id: urlId,
      //     },
      //     query: {
      //       token: urlToken,
      //     },
      //   });
      // } else {
      //   if (authStore.company.company_type === "agency") {
      //     router.push({
      //       name: "pages-request-access",
      //     });
      //   } else {
      //     router.push({
      //       name: "pages-client-request-access",
      //     });
      //   }
      // }
    }, 3000);
  } catch (error) {
    console.error("Error during Shopify OAuth token saving:", error);
    useErrorStore().setError(
      error.message || "Failed to complete Shopify OAuth"
    );
  }
};

onMounted(() => {
  console.log("Mounted hook triggered");
  saveShopifyOAuthToken();
});
</script>

<template>
  <div class="text-center" style="margin-block-start: 200px">
    <h1 class="mb-10">Redirecting back to app</h1>
    <VProgressCircular size="100" indeterminate color="primary" />
  </div>
</template>
