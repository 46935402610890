import { defineStore } from "pinia";

export const useSuccessStore = defineStore({
  id: "successStore",
  state: () => ({
    successMessage: "",
  }),
  actions: {
    setSuccessMessage(message) {
      this.successMessage = message;
    },
    clearSuccessMessage() {
      this.successMessage = null;
    },
  },
  getters: {
    isSuccess: (state) => {
      return !!state.successMessage;
    },
  },
});
