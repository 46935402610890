<script setup>
import AppTextarea from "@/@core/components/app-form-elements/AppTextarea.vue";
import AppStepper from "@/@core/components/AppStepper.vue";
import NavBar from "@/@core/components/NavBar.vue";
import AccessDetails from "@/components/AccessDetails.vue";
import ClientWorkspace from "@/components/ClientWorkspace.vue";
import DataSources from "@/components/DataSources.vue";
import InviteUsers from "@/components/InviteUsers.vue";
import Modules from "@/components/Modules.vue";
import SelectReports from "@/components/SelectReports.vue";
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useOnboardingStore } from "@/composables/store/useOnboardingStore";
import { useReportStore } from "@/composables/store/useReportStore";
import SettingsNotifications from "@/views/apps/ecommerce/settings/SettingsNotifications.vue";
import { watch, watchEffect } from "vue";
import { version } from "../../../package.json";

definePage({
  meta: {
    layout: "blank",
  },
});

// Store instances
const authStore = useAuthStore();
const reportStore = useReportStore();
const onboardingStore = useOnboardingStore();
const router = useRouter();
const route = useRoute();

// Initialize tabs and activeTab
const filteredTabs = computed(() => onboardingStore.filteredTabs);

const activeTab = computed({
  get: () => onboardingStore.activeTab,
  set: (value) => {
    onboardingStore.activeTab = value;

    const { error, ...restQuery } = route.query;

    router.push({ query: { ...restQuery, activeTab: value } });
  },
});

const dialogState = computed({
  get: () => authStore.isContactUsDialogOpen,
  set: (value) => {
    authStore.isContactUsDialogOpen = value;
  },
});

const contactFormState = computed({
  get: () => authStore.contactForm,
  set: (value) => {
    authStore.contactForm = value;
  },
});

// Function to handle tab updates based on subscription type
const updateTabsForSubscription = async () => {
  const companyId = authStore.company.company_pk;

  // Example: Determine subscription key dynamically (this logic can be adjusted)
  const subscriptionKey = "access_management-report-month"; // Replace with dynamic value if necessary

  // Update filtered tabs based on the subscription type
  onboardingStore.updateFilteredTabs(subscriptionKey);
  filteredTabs.value = onboardingStore.filteredTabs;

  // Fetch data for initialization (e.g., connected accounts)
  await reportStore.getAllConnectedAccountsByCompany(companyId);
};

const onContactUsClick = () => {
  console.log("onContactUsClick");
  dialogState.value = true;
};

const onSubmit = async () => {
  await authStore.contactUs();
  dialogState.value = false;
};

onMounted(async () => {
  onboardingStore.isOnboardingLoading = true; // Start isOnboardingLoading
  try {
    // Check query params for activeTab and set it
    console.log("route.query", route.query);

    const initialTab =
      route.query.activeTab || onboardingStore.filteredTabs[0]?.key;

    console.log("initialTab", initialTab);
    onboardingStore.activeTab = initialTab;

    // Initialize tabs and subscription
    await authStore.regenerateToken();
    updateTabsForSubscription();
  } catch (error) {
    console.error("Error initializing onboarding:", error);
  } finally {
    onboardingStore.isOnboardingLoading = false; // Stop isOnboardingLoading
  }
});

//Replace the existing watch with this updated version
watch(
  () => route.query.activeTab,
  (newValue) => {
    if (newValue && newValue !== activeTab.value) {
      const { error, ...restQuery } = route.query;

      router.replace({ query: restQuery });

      onboardingStore.activeTab = newValue;
    }
  }
);

// Watch for changes to the filtered tabs in the store
watchEffect(() => {
  filteredTabs.value = onboardingStore.filteredTabs;
});

// On load, check the query params and set the active tab
// When the user navigates to a different tab, update the query params

// Add this method to handle step updates from Modules component
const handleStepsUpdate = (steps) => {
  onboardingStore.updateActiveSteps(steps);
};

// Add watch for activeTab changes
watch(activeTab, (newValue) => {
  // Force component re-render by using key on VWindowItem
  onboardingStore.activeTab = newValue;
});

// Add these computed properties after the existing computed properties
const hasSelectedModules = computed(() => {
  const selectedTiers = authStore.companySubscriptionTiersByKeys || [];

  console.log("selectedTiers", selectedTiers);

  // Check if any tier has modules
  return selectedTiers.some(
    (tier) => tier.subscription_tier?.meta?.modules?.length > 0
  );
});

const subscriptionType = computed(() => {
  const selectedTiers = authStore.companySubscriptionTiersByKeys || [];

  // Get all modules from all tiers
  const allModules = selectedTiers.reduce((modules, tier) => {
    return modules.concat(tier.subscription_tier?.meta?.modules || []);
  }, []);

  // Remove duplicates and join with comma
  return [...new Set(allModules)].join(",");
});

const disabledSteps = computed(() => {
  const selectedTiers = authStore.companySubscriptionTiersByKeys || [];

  // In the meantime, show all tabs
  return [];

  console.log("selectedTiers", selectedTiers);

  const allowedTabs = new Set(["connect_module"]); // Module selection always allowed
  const disabledIndices = [];

  // Check subscription types with null safety
  const hasAccessManagement = selectedTiers.some(
    (tier) => tier?.includes("access_management") ?? false
  );

  console.log("hasAccessManagement", hasAccessManagement);

  const hasReporting = selectedTiers.some(
    (tier) => tier?.includes("reporting") ?? false
  );

  console.log("hasReporting", hasReporting);

  // Define allowed tabs based on subscription types
  filteredTabs.value.forEach((tab, index) => {
    const key = tab?.key;

    console.log("key", key);

    // Skip module selection tab as it's always enabled
    if (!key || key === "connect_module") return;

    // Add tabs based on subscription type
    if (
      hasAccessManagement &&
      ["connect_accounts", "connect_client_accounts", "invite_users"].includes(
        key
      )
    ) {
      allowedTabs.add(key);
    }

    console.log("allowedTabs", allowedTabs);

    if (
      hasReporting &&
      ["connect_accounts", "reporting_template", "client_insights"].includes(
        key
      )
    ) {
      allowedTabs.add(key);
    }

    // If tab is not allowed, add its index to disabled list
    if (!allowedTabs.has(key)) {
      disabledIndices.push(index);
    }
  });

  return disabledIndices;
});

// Update the watch for activeTab to prevent navigation to disabled tabs
watch(activeTab, (newValue, oldValue) => {
  const newTabIndex = filteredTabs.value.findIndex(
    (tab) => tab.key === newValue
  );

  if (disabledSteps.value.includes(newTabIndex)) {
    // Prevent navigation to disabled tab
    onboardingStore.activeTab = oldValue;

    // Show warning in console
    console.warn(
      `Tab "${newValue}" is not available with current subscription`
    );

    return;
  }

  onboardingStore.activeTab = newValue;
});
</script>

<template>
  <!-- Loading Spinner -->
  <div
    v-if="onboardingStore.isOnboardingLoading"
    class="flex justify-center items-center h-96"
  >
    <VProgressCircular indeterminate color="primary" size="50" />
  </div>
  <!-- Main Container with Fixed Height -->
  <div
    v-else
    id="center-div"
    class="rounded-2xl bg-white shadow-lg overflow-hidden h-screen flex flex-col"
  >
    <!-- Navbar -->
    <NavBar />

    <!-- Main Content -->
    <div class="flex flex-grow overflow-hidden h-full">
      <!-- Left Column -->
      <div class="flex-grow bg-gray-100 overflow-auto h-full">
        <VWindow
          v-model="activeTab"
          class="px-10 h-full overflow-scroll"
          style="overflow: scroll"
        >
          <!-- Add :key to force re-render when activeTab changes -->
          <VWindowItem
            v-for="tab in filteredTabs"
            :key="`${tab.key}-${activeTab}`"
          >
            <component
              :is="
                tab.key === 'connect_module'
                  ? Modules
                  : tab.key === 'connect_accounts'
                    ? DataSources
                    : tab.key === 'connect_client_accounts'
                      ? AccessDetails
                      : tab.key === 'reporting_template'
                        ? SelectReports
                        : tab.key === 'invite_users'
                          ? InviteUsers
                          : tab.key === 'client_insights'
                            ? ClientWorkspace
                            : SettingsNotifications
              "
            />
          </VWindowItem>
        </VWindow>
      </div>

      <!-- Right Column -->
      <div
        class="bg-white p-8 flex flex-col h-full rounded-br-2xl"
        style="
          inline-size: 400px;
          max-inline-size: 400px;
          min-inline-size: 400px;
        "
      >
        <!-- Scrollable content wrapper that includes both stepper and having trouble section -->
        <div class="flex-grow overflow-y-auto">
          <AppStepper
            v-model:current-step="activeTab"
            class="checkout-stepper"
            :items="filteredTabs"
            :direction="$vuetify.display.mdAndUp ? 'vertical' : 'vertical'"
            :disabled-steps="disabledSteps"
          />

          <!-- Having trouble section -->
          <div class="mt-8">
            <VIcon icon="tabler-ball-football" size="30" class="mb-4" />
            <div class="text-lg font-semibold mb-2">Having trouble?</div>

            <p class="text-sm text-gray-600 mb-4">
              Feel free to contact us and we will always help you through the
              process.
            </p>

            <div class="flex items-center justify-between">
              <button
                class="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg shadow-sm bg-white hover:bg-gray-100 transition-colors"
                @click="onContactUsClick"
              >
                <span class="text-gray-700 font-semibold text-sm"
                  >Contact Us</span
                >
              </button>
              <div class="text-xs text-gray-400">v{{ version }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Teleport to="#nav-drawer">
      <VDialog
        v-model="dialogState"
        class="app-customizer-dialog"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          inline-size: 600px;
        "
      >
        <VCard
          style="
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: var(--spacing-lg, 24px);
            border-radius: var(--radi-lg, 12px);

            /* Shadow/xl */
            box-shadow:
              0 20px 24px -4px rgba(10, 13, 18, 8%),
              0 8px 8px -4px rgba(10, 13, 18, 3%);
            gap: 20px;
            inline-size: 480px;
          "
        >
          <div class="flex flex-col items-center">
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                gap: var(--spacing-sm, 8px);
              "
            >
              <VAvatar size="avatarSize" color="red">
                <User />
              </VAvatar>
              <p
                style="
                  color: var(--gray-900, #181d27);
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 28px; /* 155.556% */
                "
              >
                Contact Us
              </p>
              <p
                style="
                  color: var(--gray-600, #535862);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px; /* 142.857% */
                "
              >
                We are here to help you with any questions or concerns you may
                have.
              </p>
            </div>
          </div>
          <div style="inline-size: 100%">
            <VForm
              ref="refVForm"
              style="display: flex; flex-direction: column; gap: 12px"
              @submit.prevent="onSubmit"
            >
              <AppTextField
                v-model="contactFormState.name"
                :rules="[requiredValidator]"
                prepend-inner-icon="tabler-user"
                label="Name"
                placeholder="User name"
              />

              <AppTextField
                v-model="contactFormState.email"
                :rules="[requiredValidator, emailValidator]"
                prepend-inner-icon="tabler-mail"
                label="Email address"
                type="email"
                placeholder="you@untitledui.com"
              />

              <AppTextarea
                v-model="contactFormState.description"
                label="What can we help you with?"
                placeholder="Type your message here"
              />
            </VForm>
            <div
              class="w-full flex items-center justify-between gap-[12px] mt-[32px]"
            >
              <VBtn
                height="44px"
                style="
                  display: flex;
                  flex: 1 0 0;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid var(--indigo-600, #444ce7);
                  border-radius: var(--radi-mlg, 8px);
                  background: var(--indigo-600, #444ce7);
                  box-shadow: 0 1px 2px 0 rgba(10, 13, 18, 5%);
                "
                :disabled="authStore.isContactUsLoading"
                @click="onSubmit"
              >
                <p
                  style="
                    color: var(--white, #fff);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                  "
                >
                  Send Invite
                </p>
              </VBtn>
            </div>
          </div>
        </VCard>
      </VDialog>
    </Teleport>
  </div>
</template>
