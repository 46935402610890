<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import AuthProvider from "@/views/pages/authentication/AuthProvider.vue";
import LoginImg from "@images/pages/login.png";
import { onBeforeRouteLeave } from "vue-router";
import { VForm } from "vuetify/components/VForm";

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

// const posthog = inject("posthog");

const environment =
  import.meta.env.VITE_ENV === "development" || import.meta.VITE_ENV === "local"
    ? "Development"
    : null;

const isPasswordVisible = ref(false);
const route = useRoute();
const router = useRouter();
const ability = useAbility();
const { signIn, updateSigninUserData, signin, googleCallback } = useAuthStore();

const googleAuthCode = ref(route.query.code);

const errors = ref({
  email: undefined,
  password: undefined,
});

const validationRules = {
  email: [
    (v) => {
      // Only validate if field is touched AND has been submitted
      if (!touched.value.email || (!v && !signin.isLoading)) return true;

      if (!v || v.trim() === "") {
        return "Email is required";
      }

      return /.+@.+\..+/.test(v) || "Email must be valid";
    },
  ],
  password: [
    (v) => {
      // Only validate if field is touched AND has been submitted
      if (!touched.value.password || (!v && !signin.isLoading)) return true;

      if (!v || v.trim() === "") {
        return "Password is required";
      }

      return true;
    },
  ],
};

const touched = ref({
  email: false,
  password: false,
});

const handleBlur = (field) => {
  // Only mark as touched if field has a value
  if (user.value[field]) {
    touched.value[field] = true;
  }
};

const refVForm = ref();

const user = ref({
  // email: "suzaliikmal@gmail.com",
  // password: "Soccer12",
  // email: "test1234@gmail.com",
  // password: "Soccer12",
});

// const rememberMe = ref(false)

const onSubmit = async () => {
  // If both fields are empty, don't show any errors and return early
  if (!user.value.email && !user.value.password) {
    // Reset touched state and errors
    touched.value.email = false;
    touched.value.password = false;
    errors.value.email = undefined;
    errors.value.password = undefined;

    return;
  }

  const { valid } = await refVForm.value?.validate();

  if (!valid) {
    // Only update error messages for touched fields
    const formErrors = refVForm.value?.errors || {};

    errors.value = {
      email: touched.value.email ? formErrors.email?.[0] : undefined,
      password: touched.value.password ? formErrors.password?.[0] : undefined,
    };

    return;
  }

  updateSigninUserData({
    email: user.value.email,
    password: user.value.password,
  });

  signin.isLoading = true;

  await signIn();

  await nextTick(() => {
    router.replace(route.query.to ? String(route.query.to) : "/");
    signin.isLoading = false;
  });
};

const init = async () => {
  try {
    if (googleAuthCode.value) {
      const googleCallbackResponse = await googleCallback(googleAuthCode.value);
      if (googleCallbackResponse.data.redirect === "signup") {
        router.push({
          name: "register",
          query: {
            user_email: googleCallbackResponse.data.email,
            name: googleCallbackResponse.data.name,
          },
        });
      } else {
        router.push("/");
      }
    } else {
      router.push("/");
    }
  } catch (error) {
    router.push("/");
  }
};

console.log("Created posthog", posthog); //posthog accessible anywhere!

// Add onBeforeRouteLeave navigation guard
onBeforeRouteLeave(() => {
  // Reset touched state along with other resets
  touched.value = {
    email: false,
    password: false,
  };

  // Reset form validation state
  refVForm.value?.reset();

  // Reset any error messages
  errors.value = {
    email: undefined,
    password: undefined,
  };

  // Reset user data
  user.value = {};
});

// Add a watch to clear errors when input changes
watch(
  () => user.value.email,
  () => {
    if (errors.value.email) {
      errors.value.email = undefined;
    }
  }
);

watch(
  () => user.value.password,
  () => {
    if (errors.value.password) {
      errors.value.password = undefined;
    }
  }
);

const handleCreateAccount = () => {
  // Prevent form validation by resetting touched state
  touched.value = {
    email: false,
    password: false,
  };

  // Reset any existing errors
  errors.value = {
    email: undefined,
    password: undefined,
  };

  // Reset form validation state
  refVForm.value?.reset();

  // Navigate to register
  router.push({ name: "register" });
};

init();
</script>

<template>
  <VRow
    no-gutters
    class="login-auth-wrapper bg-surface"
    data-test-id="login-page"
  >
    <!-- Left Column -->
    <VCol cols="12" sm="12" md="5" lg="5" xl="5" class="">
      <VCard flat :elevation="2" class="login-auth-card">
        <VCardText class="login-auth-content mx-10">
          <img
            data-test-id="login-logo"
            class="login-auth-logo"
            src="../assets/images/pages/login-logo.png"
            alt="Login Logo"
          />
          <div class="login-auth-header">
            <p class="login-auth-title" data-test-id="login-title">Log in</p>
            <p class="login-auth-subtitle" data-test-id="login-subtitle">
              Welcome back! Please enter your details.
            </p>
          </div>

          <VForm
            ref="refVForm"
            data-test-id="login-form"
            @submit.prevent="onSubmit"
          >
            <div class="login-auth-form">
              <div class="login-auth-fields">
                <AppTextField
                  v-model="user.email"
                  data-test-id="email-input"
                  :disabled="signin.isLoading"
                  label="Email"
                  placeholder="Enter your Email"
                  type="email"
                  autofocus
                  :rules="validationRules.email"
                  :error-messages="errors.email"
                  @blur="handleBlur('email')"
                />

                <AppTextField
                  v-model="user.password"
                  data-test-id="password-input"
                  label="Password"
                  :disabled="signin.isLoading"
                  placeholder="********"
                  :rules="validationRules.password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="errors.password"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                  @blur="handleBlur('password')"
                />

                <div class="">
                  <RouterLink
                    data-test-id="forgot-password-link"
                    class="text-primary ms-2 mb-1"
                    :to="{ name: 'forgot-password' }"
                  >
                    Forgot Password?
                  </RouterLink>
                </div>
                <div>
                  <VBtn
                    data-test-id="login-submit-btn"
                    :loading="signin.isLoading"
                    block
                    type="submit"
                  >
                    <p class="text-sm">Login</p>
                  </VBtn>
                </div>
              </div>

              <div v-show="!signin.isLoading" class="login-auth-divider pb-4">
                <p>Or Login with</p>
              </div>

              <div v-show="!signin.isLoading" class="text-center">
                <AuthProvider
                  data-test-id="auth-provider"
                  :is-google-btn-loading="!!googleAuthCode"
                />
              </div>
            </div>
          </VForm>

          <div v-show="!signin.isLoading" class="login-auth-footer">
            <span>Don't have an account?</span>
            <RouterLink
              data-test-id="create-account-link"
              class="text-primary ms-2"
              :to="{ name: 'register' }"
              @click.stop.prevent="handleCreateAccount"
            >
              Create now
            </RouterLink>
          </div>
        </VCardText>
      </VCard>
    </VCol>

    <!-- Right Column -->
    <VCol
      cols="12"
      sm="12"
      md="7"
      lg="7"
      xl="7"
      class="d-none d-md-flex login-auth-right-column"
    >
      <div class="login-solid-circle"></div>
      <div class="login-right-content">
        <VImg
          :src="LoginImg"
          alt="App Screenshot"
          class="login-auth-screenshot"
        />
        <h1 class="login-right-title">
          One click to link with Google Ads, Google Analytics, Facebook Ads,
          Instagram, LinkedIn
        </h1>
        <p class="login-right-description">
          Log in to manage your clients, streamline workflows, and access secure
          permissions—all from one dashboard.
        </p>
      </div>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";

.login-auth-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--v-theme-primary));
  min-block-size: 100vh;

  @media (max-width: 600px) {
    padding: 1rem;
    min-block-size: auto;
  }
}

.login-auth-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  block-size: auto;
  inline-size: 100%;
  min-block-size: 100vh;
  transition: none;

  @media (max-width: 600px) {
    block-size: auto;
    min-block-size: 100vh;
  }
}

.login-auth-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  block-size: auto;
  inline-size: min(80%, 600px);
  margin-block: 0;
  margin-block-start: 6rem;
  margin-inline: auto;
  min-block-size: 600px;
  place-self: center;

  @media (max-width: 600px) {
    inline-size: 100%;
    margin-inline: 0;
    padding-block: 1rem;
    padding-inline: 1rem;
  }
}

.login-auth-logo {
  block-size: 50px;
  inline-size: 200px;
  margin-block-end: 50px;

  @media (max-width: 600px) {
    block-size: 38px;
    inline-size: 150px;
    margin-block-end: 30px;
  }
}

.login-auth-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-block-end: 20px;
}

.login-auth-title {
  color: var(--gray-900, #181d27);
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: 44px;

  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 36px;
  }
}

.login-auth-subtitle {
  color: var(--gray-600, #535862);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.login-auth-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  inline-size: 100%;
  min-block-size: 400px;
}

.login-auth-fields {
  display: flex;
  flex-direction: column;
  block-size: auto;
  gap: 24px;
  margin-block-end: 20px;

  .v-input {
    margin-block-end: 0 !important;
  }

  .v-text-field {
    .v-input__details {
      margin-block-start: 4px !important;
      min-block-size: 20px;
    }
  }

  @media (max-width: 600px) {
    inline-size: 100%;
  }
}

.login-auth-divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-block: 0.5rem;
  margin-inline: 0;
}

.login-auth-footer {
  margin-block-start: 1.5rem;
  text-align: center;
}

.login-auth-right-column {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background: linear-gradient(
    135deg,
    rgb(var(--v-theme-primary)) 0%,
    rgba(var(--v-theme-primary), 0.8) 100%
  );
  color: white;
  min-block-size: 100vh;

  // Add subtle animated gradient overlay
  &::before {
    position: absolute;
    animation: gradientShift 8s ease-in-out infinite alternate;
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 10%) 0%,
      rgba(255, 255, 255, 5%) 100%
    );
    content: "";
    inset: 0;
  }

  // Add floating circles background
  &::after {
    position: absolute;
    animation: floatingCircles 15s ease-in-out infinite alternate;
    background-image: radial-gradient(
        circle at 20% 30%,
        rgba(255, 255, 255, 10%) 0%,
        transparent 50%
      ),
      radial-gradient(
        circle at 80% 70%,
        rgba(255, 255, 255, 8%) 0%,
        transparent 50%
      );
    content: "";
    inset: 0;
  }
}

.login-right-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeInUp 0.8s ease-out;
  max-inline-size: 90%;
}

.login-auth-screenshot {
  position: relative;
  border-radius: 16px;
  animation: float 6s ease-in-out infinite;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 20%);
  inline-size: 100%;
  margin-block-end: 3rem;
  transform-style: preserve-3d;

  // Add reflection effect
  &::after {
    position: absolute;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 20%),
      transparent
    );
    block-size: 40px;
    content: "";
    filter: blur(8px);
    inset-block-start: 100%;
    inset-inline: 0;
    transform: translateY(-20px);
  }
}

.login-right-title {
  animation: fadeInUp 0.8s ease-out 0.2s both;
  background: linear-gradient(to right, #fff, #f0f0f0);
  background-clip: text;
  color: transparent;
  font-size: clamp(28px, 4vw, 40px);
  font-weight: 800;
  line-height: 1.3;
  margin-block-end: 1.5rem;
  text-align: center;
}

.login-right-description {
  animation: fadeInUp 0.8s ease-out 0.4s both;
  color: rgba(255, 255, 255, 90%);
  font-size: clamp(16px, 2vw, 18px);
  line-height: 1.6;
  margin-block-end: 2rem;
  max-inline-size: 90%;
  text-align: center;
}

// Remove the solid circle and replace with a more subtle effect
.login-solid-circle {
  display: none;
}

// Animation keyframes
@keyframes float {
  0%,
  100% {
    transform: translateY(0) rotateX(2deg);
  }

  50% {
    transform: translateY(-20px) rotateX(-2deg);
  }
}

@keyframes gradientShift {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }

  100% {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

@keyframes floatingCircles {
  0% {
    transform: translateX(0) translateY(0);
  }

  100% {
    transform: translateX(-20px) translateY(-20px);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Add smooth transitions for hover effects
.login-auth-screenshot {
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 25px 50px rgba(0, 0, 0, 25%);
    transform: translateY(-5px);
  }
}

// Responsive adjustments
@media (max-width: 1200px) {
  .login-auth-right-column {
    padding: 2rem;
  }

  .login-right-content {
    max-inline-size: 95%;
  }
}

@media (max-width: 960px) {
  .login-auth-right-column {
    display: none;
  }
}

@media (max-width: 600px) {
  .login-auth-wrapper {
    padding: 1rem;
  }

  .login-auth-card {
    block-size: auto;
    min-block-size: 100vh;
  }

  .login-auth-content {
    inline-size: 100%;
    margin-inline: 0;
    padding-block: 1rem;
    padding-inline: 1rem;
  }

  .login-auth-logo {
    block-size: 38px;
    inline-size: 150px;
    margin-block-end: 30px;
  }

  .login-auth-title {
    font-size: 28px;
    line-height: 36px;
  }

  .login-auth-subtitle {
    font-size: 14px;
    line-height: 20px;
  }

  .login-auth-fields {
    inline-size: 100%;
  }
}

:deep(.v-field) {
  transition: none !important;

  &.v-field--focused,
  &.v-field--active {
    block-size: unset !important;
    min-block-size: unset !important;
    transform: none !important;
  }

  .v-field__outline,
  .v-field__overlay {
    transform: none !important;
    transition: none !important;
  }
}

:deep(.v-text-field) {
  padding: 0 !important;
  margin: 0 !important;
  transition: none !important;

  .v-input__control {
    block-size: 44px !important;
    min-block-size: 44px !important;
  }

  .v-field__input {
    block-size: 44px !important;
    min-block-size: 44px !important;
    padding-block: 0 !important;
  }

  .v-input__details {
    padding: 0 !important;
    margin-block: 4px 0 !important;
    min-block-size: 0 !important;
  }

  .v-label {
    margin-block-end: 4px !important;
    transition: none !important;
  }
}

// Prevent any animations on form elements
:deep(.v-input),
:deep(.v-field),
:deep(.v-text-field),
:deep(.v-btn) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

:deep(.v-btn) {
  margin: 0 !important;
  block-size: 44px !important;
  min-block-size: 44px !important;
  padding-block: 0 !important;
  padding-inline: 16px !important;

  .v-btn__content {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
}
</style>
