import { defineStore } from "pinia";

export const useErrorStore = defineStore({
  id: "errorStore",
  state: () => ({
    errorMessage: "",
  }),
  actions: {
    setErrorMessage(message) {
      this.errorMessage = message;
    },
    clearErrorMessage() {
      this.errorMessage = null;
    },
  },
  getters: {
    isError: (state) => {
      return !!state.errorMessage;
    },
  },
});
