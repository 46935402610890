<script setup>
import BaseDashboard from "@/@core/components/dashboard/BaseDashboard.vue";
import { useDashboardStore } from "@/composables/store/useDashboardStore";
import { useReportStore } from "@/composables/store/useReportStore";
import amazonEcommerce from "@/utils/dashboard/amazon_ecommerce";
import blinkit from "@/utils/dashboard/blinkit";
import flipkartGrocery from "@/utils/dashboard/flipkart_grocery";
import flipkartNational from "@/utils/dashboard/flipkart_national";
import googleAds from "@/utils/dashboard/google_ads";
import googleAdsPerformance1 from "@/utils/dashboard/google_ads_performance_1";
import googleAdsPerformance2 from "@/utils/dashboard/google_ads_performance_2";
import googleAnalytics from "@/utils/dashboard/google_analytics";
import indiaEcommerce from "@/utils/dashboard/india_ecommerce";
import instacart from "@/utils/dashboard/instacart";
import metaAds from "@/utils/dashboard/meta_ads";
import myntra from "@/utils/dashboard/myntra";
import oneMG from "@/utils/dashboard/onemg";
import zepto from "@/utils/dashboard/zepto";
import zepto2 from "@/utils/dashboard/zepto2";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const dashboardStore = useDashboardStore();
const { selectedDashboard } = storeToRefs(dashboardStore);
const reportStore = useReportStore();
const route = useRoute();
const companyId = route.query.company_id;

// Default date range (7 days ago to today)
const dateRange = ref([
  new Date(new Date().setDate(new Date().getDate() - 7)), // 7 days ago
  new Date(), // Today
]);

const companyName = computed(
  () => dashboardStore?.selectedCompany?.name || "Sample Company"
);

// Watch for date range changes
watch(dateRange, (value) => {
  console.log("dateRange", value);

  const startDate = value[0].toISOString().split("T")[0]; // Format start date as YYYY-MM-DD
  const endDate = value[1].toISOString().split("T")[0]; // Format end date as YYYY-MM-DD

  // Update dashboardStore's filters and individual date properties
  dashboardStore.filters.dateRange = `${startDate}~${endDate}`;
  dashboardStore.startDate = startDate; // Set the start date in YYYY-MM-DD format
  dashboardStore.endDate = endDate; // Set the end date in YYYY-MM-DD format
});

// Step 1: Get all dashboards for a particular company [client insights]

// Store instances
const selectedAccountId = ref(null);

const availableReports = ref([
  { label: "Google Analytics ", value: "google_analytics_1" },
  { label: "Google Ads ", value: "google_ads_" },
  { label: "Meta Ads ", value: "meta_ads_1" },
]);

const baseCardConfig = ref([]);
const isLoading = ref(false); // Loading state

const dashboardMapper = {
  google_analytics_1: googleAnalytics,
  google_ads_1: googleAds,
  meta_ads_1: metaAds,
  google_ads_performance_1: googleAdsPerformance1,
  google_ads_performance_2: googleAdsPerformance2,
  amazon_ecommerce_1: amazonEcommerce,
  india_ecommerce_1: indiaEcommerce,
  blinkit_1: blinkit,
  zepto_1: zepto,
  zepto_2: zepto2,
  flipkart_national_1: flipkartNational,
  flipkart_grocery_1: flipkartGrocery,
  instacart_1: instacart,
  myntra_1: myntra,
  onemg_1: oneMG,
};

// Store the propertyIds for the selected account
const selectedAccountPropertyIds = ref([]);

const areAllDropdownsSelected = computed(() => {
  const isGoogleAnalyticsValid =
    filteredPlatforms.value.includes("google_analytics") &&
    selectedAccountId.value &&
    dashboardStore.property_id;

  const isGoogleAdsValid =
    filteredPlatforms.value.includes("google_ads") &&
    dashboardStore.customer_id;

  const isMetaAdsValid =
    filteredPlatforms.value.includes("meta_ads") && dashboardStore.account_id;

  return (
    selectedReportKey.value &&
    (isGoogleAnalyticsValid || isGoogleAdsValid || isMetaAdsValid)
  );
});

const selectedReportKey = computed(() => {
  // First check URL query parameter
  const urlDashboardType = route.query.dashboard_type;
  if (urlDashboardType) return urlDashboardType;

  // Fallback to existing logic
  return selectedDashboard.value?.dashboard?.key || null;
});

const init = async () => {
  const {
    company_id,
    dashboard_type,
    start_date,
    end_date,
    property_id,
    customer_id,
    account_id,
  } = route.query;

  // Set date range from URL params or default to 7 days
  const defaultStartDate = new Date(
    new Date().setDate(new Date().getDate() - 7)
  );

  const defaultEndDate = new Date();

  dateRange.value = [
    start_date ? new Date(start_date) : defaultStartDate,
    end_date ? new Date(end_date) : defaultEndDate,
  ];

  // Set dates in store
  const startDate = dateRange.value[0].toISOString().split("T")[0];
  const endDate = dateRange.value[1].toISOString().split("T")[0];

  dashboardStore.startDate = startDate;
  dashboardStore.endDate = endDate;
  dashboardStore.filters.dateRange = `${startDate}~${endDate}`;

  // Set other store values from URL params
  if (property_id) dashboardStore.property_id = property_id;
  if (customer_id) dashboardStore.customer_id = customer_id;
  if (account_id) dashboardStore.account_id = account_id;

  // Auto-select dashboard type if provided
  if (dashboard_type) {
    dashboardStore.filters.dashboardType = dashboard_type;
  }

  // Fetch necessary data if company_id is provided
  if (company_id) {
    await reportStore.getAllSystemDashboards();
    await reportStore.getCompaniesWithDashboardsByClient(company_id);
    await dashboardStore.getSelectedCompanyDashboards(company_id);

    if (!dashboard_type) {
      dashboardStore.filters.dashboardType =
        dashboardStore.companyDashboards?.[0]?.company_dashboards_pk;
    }
    await reportStore.getAllConnectedAccountsByCompany(company_id);
  }

  // Initialize dashboards after all data is loaded
  if (selectedReportKey.value) {
    baseCardConfig.value = dashboardMapper[selectedReportKey.value];
  }
};

// Filtered platforms for conditional rendering
const filteredPlatforms = computed(() => {
  return selectedDashboard.value
    ? selectedDashboard.value?.dashboard?.platforms
    : [];
});

// Watch for changes in the selected report key and update baseCardConfig with loading state
watch(selectedReportKey, async (newValue) => {
  isLoading.value = true;
  await new Promise((resolve) => setTimeout(resolve, 500));
  if (newValue) {
    baseCardConfig.value = dashboardMapper[newValue];
  }
  isLoading.value = false;
});

// Watch for changes in selected Google Analytics account
watch(selectedAccountId, (newAccountId) => {
  if (!newAccountId || !filteredGoogleAnalyticsIds.value) return;

  // Find the selected account to retrieve its propertyIds
  const selectedAccount = filteredGoogleAnalyticsIds.value.find(
    (account) => account.id === newAccountId
  );

  // If account is found, set the propertyIds for the new autocomplete
  if (selectedAccount) {
    selectedAccountPropertyIds.value = selectedAccount.propertyIds || [];
  }
});

// Fetch filtered Google Analytics IDs
const filteredGoogleAnalyticsIds = computed(() => {
  const filteredAccounts = reportStore.accounts.filter(
    (account) => account.company_id === route.query.company_id
  );

  return filteredAccounts?.[0]?.connected_accounts?.google_analytics;
});

// Fetch filtered Google Ads IDs
const filteredGoogleAdsIds = computed(() => {
  const filteredAccounts = reportStore.accounts.filter(
    (account) => account.company_id === route.query.company_id
  );

  return filteredAccounts?.[0]?.connected_accounts?.google_ads;
});

// Fetch filtered Meta Ads IDs
const filteredMetaAdsIds = computed(() => {
  const filteredAccounts = reportStore.accounts.filter(
    (account) => account.company_id === route.query.company_id
  );

  return filteredAccounts?.[0]?.connected_accounts?.meta_ads;
});

const isPrinting = ref(false);

init();
</script>

<template>
  <div
    id="center-div"
    class="rounded-2xl bg-white shadow-lg flex flex-col h-screen"
  >
    <!-- Main Content -->
    <div class="flex flex-grow">
      <!-- Left Column -->
      <div class="flex-grow bg-gray-100 overflow-auto">
        <div class="px-10 py-4 h-full bg-white">
          <div class="mt-10">
            <div id="dashboard-content" class="mt-10">
              <BaseDashboard
                :card-config="baseCardConfig"
                :fake-data="!companyId"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* Print styles */
@media print {
  #center-div {
    overflow: visible !important;
    block-size: auto !important;
  }

  .no-print {
    display: none !important;
  }

  #dashboard-content {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
