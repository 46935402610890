<script setup>
import { passwordValidator, requiredValidator } from "@/@core/utils/validators";
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useSuccessStore } from "@/composables/store/useSuccessStore";
import atrLogo from "@images/logos/atr.png";

const route = useRoute();
const authStore = useAuthStore();
const successStore = useSuccessStore();
const router = useRouter();

const isLoading = ref(false);

const email = ref(route.query.email || "");
const password = ref("");
const token = ref(route.query.token || "");
const refVForm = ref(null);

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

const init = async () => {
  if (!token.value && !email.value) {
    router.push({ name: "login" });
  }
};

const onSubmit = async () => {
  const isValid = await refVForm.value.validate();
  if (!isValid.valid) return;

  try {
    isLoading.value = true;

    const resetPasswordResponse = await authStore.resetPassword({
      email: email.value,
      token: token.value,
      newPassword: password.value,
    });

    if (resetPasswordResponse) {
      successStore.setSuccessMessage("Password reset successfully");
      router.push({ name: "login" });
    }
  } catch (error) {
    console.error("Reset password error:", error);
  } finally {
    isLoading.value = false;
  }
};

init();
</script>

<template>
  <VRow class="auth-wrapper bg-surface" no-gutters>
    <VCol cols="12" class="d-flex align-center justify-center">
      <VCard flat :max-width="500" class="my-12 pa-4">
        <VCardText class="text-center">
          <VImg
            data-test-id="atr-logo"
            class="pb-4 mx-auto"
            max-width="200px"
            :src="atrLogo"
          />
          <h4 data-test-id="reset-password-title" class="text-h4 mb-1">
            Reset Password? 🔒
          </h4>
          <p class="mb-0">
            Enter your new password below to reset your password.
          </p>
        </VCardText>

        <VCardText>
          <VForm
            ref="refVForm"
            data-test-id="reset-password-form"
            @submit.prevent="onSubmit"
          >
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="email"
                  autofocus
                  disabled
                  label="Email"
                  type="email"
                  data-test-id="email-input"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="password"
                  autofocus
                  :rules="[requiredValidator, passwordValidator]"
                  label="Password"
                  type="password"
                  data-test-id="password-input"
                />
              </VCol>

              <!-- Reset link -->
              <VCol cols="12">
                <VBtn
                  :loading="isLoading"
                  block
                  type="submit"
                  data-test-id="submit-button"
                >
                  {{
                    token && route.query.rid
                      ? "Confirm Reset Password"
                      : "Send Reset Link"
                  }}
                </VBtn>
              </VCol>

              <!-- back to login -->
              <VCol cols="12" class="text-center">
                <RouterLink
                  class="d-inline-flex align-center"
                  :to="{ name: 'login' }"
                  data-test-id="back-to-login-link"
                >
                  <VIcon icon="tabler-chevron-left" class="flip-in-rtl" />
                  <span>Back to login</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
