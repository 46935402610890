import ability, { defineAbilitiesFor } from "@/plugins/casl/ability";
import { apiInstance, sendGraphQLRequest } from "@/utils/axios";
import { LOCALSTORAGE } from "@/utils/constants";
import * as Sentry from "@sentry/vue";
import { useStorage } from "@vueuse/core";
import "core-js/stable/atob";
import { jwtDecode } from "jwt-decode";
import { defineStore } from "pinia";

export const useAuthStore = defineStore({
  id: "authStore",
  state: () => ({
    hasuraAuthToken:
      localStorage.getItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN) || null,
    signup: {
      user: {
        name: "",
        email: "",
        password: "",
        company_name: "",
        company_website: "",
        company_type: "",
      },
      isLoading: false,
      response: null,
    },
    isContactUsDialogOpen: false,
    isContactUsLoading: false,
    contactForm: {
      name: "",
      email: "",
      description: "",
    },
    signin: {
      user: {
        email: "",
        password: "",
      },
      isLoading: false,
      response: null,
    },
    user: {
      id: localStorage.getItem(LOCALSTORAGE.USER_ID) || null,
      user_type: localStorage.getItem(LOCALSTORAGE.USER_ROLE) || null,
    },
    company: {
      id: localStorage.getItem(LOCALSTORAGE.COMPANY_ID) || null,
      company_type: localStorage.getItem(LOCALSTORAGE.COMPANY_TYPE) || null,
    },
    subscription: {
      id: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_ID) || null,
      status: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_STATUS) || null,
      key: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_KEY) || null,
      startDate:
        localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_START_DATE) || null,
      endDate: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_END_DATE) || null,
    },
    subscriptionTiers: [],
    isSubscriptionTiersLoading: false,
    currentAgency: {},
    profilePicture: "",

    isForgetPasswordLoading: false,
    isResetPasswordLoading: false,
    isCreateCompanyDrawerOpen: false,
    isCreateCompanyLoading: false,
    createCompanyForm: {
      name: "",
      email: "",
      website: "",
    },
    createUserForm: {
      name: "",
    },
    isCreateUserLoading: false,
    isCreateUserDrawerOpen: false,
    isConfirmCancelSubscriptionDrawerOpen: false,
    isConfirmCancelSubscriptionLoading: false,
    confirmCancelSubscriptionForm: {
      feedback: "",
      featureRequest: "",
    },
    invoices: [],
    isSubscriptionRequestLoading: false,
    presignedProfilePictureUrl: null,
  }),
  actions: {
    async signUp() {
      try {
        this.signup.isLoading = true;

        const response = await apiInstance.post(
          `/api/signup`,
          {
            formFields: [
              { id: "name", value: this.signup.user.name },
              { id: "email", value: this.signup.user.email },
              { id: "password", value: this.signup.user.password },
              { id: "company_name", value: this.signup.user.company_name },
              {
                id: "company_website",
                value: this.signup.user.company_website,
              },
              { id: "company_type", value: this.signup.user.company_type },
              { id: "company_id", value: this.signup.user?.company_id || null },
            ],
          },
          {
            headers: {
              rid: "emailpassword",
              "Content-Type": "application/json",
            },
          }
        );

        this.signup.response = response.data;

        const hasuraAuthToken = response.headers["st-access-token"];
        if (hasuraAuthToken) {
          this.setAuthToken(hasuraAuthToken);
        }

        const [userData, companyData] = await Promise.all([
          this.getUserById(this.user?.id),
          this.getCompany(this.company?.id),
        ]);

        this.user = userData.data?.users_by_pk;
        this.company = companyData.data?.companies_by_pk;
        localStorage.setItem(
          LOCALSTORAGE.COMPANY_TYPE,
          this.company?.company_type
        );
      } catch (error) {
        this.signup.response = null;
      } finally {
        this.signup.isLoading = false;
      }
    },
    async signIn() {
      // const posthog = inject("posthog");
      this.signin.isLoading = true;
      try {
        const response = await apiInstance.post(
          `/api/signin`,
          {
            formFields: [
              { id: "email", value: this.signin.user.email },
              { id: "password", value: this.signin.user.password },
            ],
          },
          {
            headers: {
              rid: "emailpassword",
              "Content-Type": "application/json",
            },
          }
        );

        const hasuraAuthToken = response.headers["st-access-token"];
        if (hasuraAuthToken) {
          this.setAuthToken(hasuraAuthToken);
        }

        this.signin.response = response.data;

        const [userData, companyData] = await Promise.all([
          this.getUserById(this.user?.id),
          this.getCompany(this.company?.id),
        ]);

        posthog?.identify(this.user?.id, {
          email: this.user?.email,
          name: this.user?.name,
          company: this.company?.name,
          company_type: this.company?.company_type,
        });

        this.user = userData.data?.users_by_pk;
        this.company = companyData.data?.companies_by_pk;
        localStorage.setItem(
          LOCALSTORAGE.COMPANY_TYPE,
          this.company?.company_type
        );
      } catch (error) {
        this.signin.response = null;
      }
    },
    updateSignupUserData(payload) {
      this.signup.user = { ...this.signup.user, ...payload };
    },
    updateSigninUserData(payload) {
      this.signin.user = { ...this.signin.user, ...payload };
    },
    async getAgencyByClientId(clientId) {
      const query = `
        query GetAgency($clientId: uuid!) {
          agency_clients(where: {client_fk: {_eq: $clientId}}, limit: 1) {
            agency {
              company_pk
              name
              email
              website
              company_type
              profile_picture_url
            }
          }
        }
      `;

      return sendGraphQLRequest(query, { clientId });
    },
    initAuthData() {
      if (
        this.hasuraAuthToken &&
        this.user?.id &&
        this.company?.id &&
        !this.user?.name &&
        !this.company?.name
      ) {
        Promise.all([
          this.getUserById(this.user?.id),
          this.getCompany(this.company?.id),
          this.getAgencyByClientId(this.company?.id),
        ]).then((values) => {
          this.user = values[0].data?.users_by_pk;
          this.company = values[1].data?.companies_by_pk;
          this.currentAgency = values[2].data?.agency_clients[0]?.agency || {};
          localStorage.setItem(
            LOCALSTORAGE.COMPANY_TYPE,
            this.company?.company_type
          );

          if (this.company?.profile_picture_url) {
            console.log(
              "this.company?.profile_picture_url",
              this.company?.profile_picture_url
            );
            this.getPresignedProfilePictureUrl(
              this.company?.profile_picture_url
            );
          }

          Sentry.withScope((scope) => {
            scope.setUser({
              id: this.user?.id,
              user: this.user,
              company: this?.company,
            });
          });
        });
      }
    },
    setAuthToken(token) {
      try {
        if (!token) {
          console.error("No token provided to setAuthToken");

          return;
        }

        // Basic validation that the token is in the correct format
        if (!token.includes(".") || token.split(".").length !== 3) {
          console.error("Token format is invalid");

          return;
        }

        this.hasuraAuthToken = token;
        localStorage.setItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN, token);

        const decodedToken = jwtDecode(token);

        const userId =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-user-id"];

        const companyId =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-company-id"];

        const subscriptionStatus =
          decodedToken["https://hasura.io/jwt/claims"][
            "x-hasura-subscription-status"
          ];

        const subscriptionKey =
          decodedToken["https://hasura.io/jwt/claims"][
            "x-hasura-subscription-key"
          ];

        const subscriptionStartDate =
          decodedToken["https://hasura.io/jwt/claims"][
            "x-hasura-subscription-start-date"
          ];

        const subscriptionEndDate =
          decodedToken["https://hasura.io/jwt/claims"][
            "x-hasura-subscription-end-date"
          ];

        const userRole =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-default-role"];

        localStorage.setItem(LOCALSTORAGE.USER_ID, userId);
        localStorage.setItem(LOCALSTORAGE.COMPANY_ID, companyId);
        localStorage.setItem(
          LOCALSTORAGE.SUBSCRIPTION_STATUS,
          subscriptionStatus
        );
        localStorage.setItem(LOCALSTORAGE.USER_ROLE, userRole);
        localStorage.setItem(LOCALSTORAGE.SUBSCRIPTION_KEY, subscriptionKey);
        localStorage.setItem(
          LOCALSTORAGE.SUBSCRIPTION_START_DATE,
          subscriptionStartDate
        );
        localStorage.setItem(
          LOCALSTORAGE.SUBSCRIPTION_END_DATE,
          subscriptionEndDate
        );

        // Update subscription
        this.subscription = {
          id: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_ID) || null,
          status:
            localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_STATUS) || null,
          key: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_KEY) || null,
          startDate: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_START_DATE),
          endDate: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_END_DATE),
        };

        const newAbility = defineAbilitiesFor(userRole);

        useStorage("userAbilityRules").value = newAbility.rules;
        ability.update(newAbility.rules);

        this.user = {
          ...this.user,
          id: userId,
        };
        this.company = {
          ...this.company,
          id: companyId,
        };

        Sentry.withScope((scope) => {
          scope.setUser({
            id: this.user?.id,
            user: this.user,
            company: this?.company,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    logout() {
      localStorage.clear();

      // useCookie("userAbilityRules").value = [];
      this.signup.user = {
        name: "",
        email: "",
        password: "",
        company_name: "",
        company_website: "",
        company_type: "",
      };
      this.signin.user = { email: "", password: "" };
    },
    contactUs() {
      this.isContactUsLoading = true;
      try {
        apiInstance.post("/contact-us", {
          name: this.contactForm.name,
          email: this.contactForm.email,
          description: this.contactForm.description,
        });
        this.contactForm = {
          name: "",
          email: "",
          description: "",
        };
      } catch (error) {
      } finally {
        this.isContactUsLoading = false;
      }
    },
    async getUserData() {
      const query = `
        query {
          users {
            email
            name
            user_pk
            user_type
            created_at
            updated_at
          }
        }
      `;

      return sendGraphQLRequest(query);
    },
    async getUserById(userId) {
      const query = `
        query GetUser($user_id: uuid!) {
          users_by_pk(user_pk: $user_id) {
            company {
              company_pk
              company_type
              name
              website
              created_at
              updated_at
          }
          email
          name
          user_pk
          user_type
          created_at
          updated_at
        }
      }
      `;

      return sendGraphQLRequest(query, { user_id: userId });
    },
    async getAgencyCompanyData() {
      const query = `
        query {
          companies {
            company_pk
            company_type
            name
            website
            created_at
            updated_at
          }
      `;

      return sendGraphQLRequest(query);
    },
    async getCompany(companyId) {
      const query = `
        query GetCompany($company_id: uuid!) {
          companies_by_pk(company_pk: $company_id) {
            company_pk
            company_type
            profile_picture_url
            name
            email
            website
            created_at
            updated_at
            is_google_ads_connected
            is_meta_ads_connected
            is_shopify_connected
            subscription_transactions(where: {status: {_eq: "active"}}, limit: 1, order_by: {updated_at: desc}) {
              subscription_transaction_pk
              status
              start_date
              end_date
              updated_at
              subscription_transaction_tiers {
                subscription_tier {
                  tier_name
                  key
                  subscription_tier_pk
                  price
                  meta
                }
              }
            }
            users {
              email
              name
              user_pk
              user_type
              created_at
              updated_at
            }
          }
        }
      `;

      return sendGraphQLRequest(query, { company_id: companyId });
    },

    async getAgencyProfilePicture(companyId) {
      try {
        const response = await apiInstance.post(
          `/onboarding/get-company-profile-picture`,
          {
            company_id: companyId,
            status: "public",
          }
        );

        this.profilePicture = response.data.data;

        return response.data.data;
      } catch (error) {
        console.error("Error fetching dashboards", error);
      }
    },

    async getCompanyProfilePicture(companyId) {
      try {
        const query = `
   query GetCompanyProfilePicture($company_id: uuid!) {
        companies_by_pk(company_pk: $company_id) {
      profile_picture_url
      }
  }
    `;

        const data = await sendGraphQLRequest(query, {
          company_id: companyId,
        });

        this.profilePicture = data.data.companies_by_pk;

        return data.data.companies_by_pk;
      } catch (error) {
        console.error("Error fetching dashboards", error);
      }
    },

    async googleSignIn() {
      const response = await apiInstance.post("/api/auth/signup/google");

      window.open(response.data.url, "_self");

      return response;
    },
    async googleCallback(code) {
      const response = await apiInstance.post("/api/auth/callback/google", {
        code,
      });

      if (response.data.redirect === "login") {
        const hasuraAuthToken = response.headers["st-access-token"];
        if (hasuraAuthToken) {
          this.setAuthToken(hasuraAuthToken);
        }

        const [userData, companyData] = await Promise.all([
          this.getUserById(this.user?.id),
          this.getCompany(this.company?.id),
        ]);

        this.user = userData.data?.users_by_pk;
        this.company = companyData.data?.companies_by_pk;
        localStorage.setItem(
          LOCALSTORAGE.COMPANY_TYPE,
          this.company?.company_type
        );
      }

      return response;
    },
    async forgetPassword(email) {
      try {
        this.isForgetPasswordLoading = true;

        const response = await apiInstance.post("/api/auth/reset-password", {
          email,
        });

        this.isForgetPasswordLoading = false;

        return response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isForgetPasswordLoading = false;
      }
    },
    async resetPassword({ email, token, newPassword }) {
      try {
        this.isResetPasswordLoading = true;

        const response = await apiInstance.post(
          "/api/auth/reset-password/token",
          { email, token, password: newPassword }
        );

        this.isResetPasswordLoading = false;

        return response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isResetPasswordLoading = false;
      }
    },
    async getCompanyDetails() {
      try {
        const companyDetails = await this.getCompany(
          this.company?.id || this.company.company_pk
        );

        this.company = {
          ...this.company,
          ...companyDetails.data.companies_by_pk,
        };

        this.presignedProfilePictureUrl = null;
      } catch (error) {
        console.log(error);
      }
    },
    async isValidSubscription() {
      const companyResponse = await this.getCompany(
        this.company.id || this.company?.company_pk
      );

      const activeSubscription =
        companyResponse?.data?.companies_by_pk?.subscription_transactions[0];

      if (!activeSubscription) return false;

      // Check if there are any non-trial tiers in the subscription
      return activeSubscription.subscription_transaction_tiers.some(
        ({ tier }) => tier?.key !== "trial"
      );
    },
    async updateCompanyDetails() {
      this.isCreateCompanyLoading = true;
      try {
        await apiInstance.put(`/company`, {
          name: this.createCompanyForm.name,
          email: this.createCompanyForm.email,
          website: this.createCompanyForm.website,
        });
        await this.getCompanyDetails();
        await this.regenerateToken();
        this.isCreateCompanyDrawerOpen = false;
      } catch (error) {
      } finally {
        this.isCreateCompanyLoading = false;
      }
    },
    async updateUserDetails() {
      this.isCreateUserLoading = true;
      try {
        await apiInstance.put(`/user`, {
          name: this.createUserForm.name,
        });

        const user = await this.getUserById(this.user.id || this.user.user_pk);

        await this.regenerateToken();
        this.user = {
          ...this.user,
          ...user.data.users_by_pk,
        };
        this.isCreateUserDrawerOpen = false;
      } catch (error) {
      } finally {
        this.isCreateUserLoading = false;
      }
    },
    async regenerateToken() {
      try {
        const response = await apiInstance.post("/api/regenerate-token");
        const hasuraAuthToken = response.headers["st-access-token"];
        if (hasuraAuthToken) {
          this.setAuthToken(hasuraAuthToken);
        }

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    async cancelSubscription() {
      try {
        this.isConfirmCancelSubscriptionLoading = true;

        const response = await apiInstance.post("/subscription/cancel", {
          feedback: this.confirmCancelSubscriptionForm.feedback,
          featureRequest: this.confirmCancelSubscriptionForm.featureRequest,
        });

        await this.regenerateToken();
        await this.getCompanyDetails();
        this.isConfirmCancelSubscriptionLoading = false;

        return response;
      } catch (error) {
      } finally {
        this.isConfirmCancelSubscriptionLoading = false;
      }
    },

    // Get company invoice /subscription/invoices
    async getSubscriptionInvoices() {
      try {
        const response = await apiInstance.get("/subscription/invoices");

        this.invoices = response.data.data;

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    async getTiers() {
      const query = `
      query {
         subscription_tiers(where: {is_deleted: {_eq: false}}, order_by: {order: asc}) {
    subscription_tier_pk
    tier_name
    price
    subscription_type
    interval_count
    stripe_price_id
    key
    subscription_id
    meta
  }
    }
   `;

      try {
        this.isSubscriptionTiersLoading = true;

        const data = await sendGraphQLRequest(query);

        this.subscriptionTiers = data.data.subscription_tiers;

        return this.subscriptionTiers;
      } catch (error) {
      } finally {
        this.isSubscriptionTiersLoading = false;
      }
    },
    async subscribeToTier(tiers) {
      try {
        if (!tiers?.length) return;
        this.isSubscriptionRequestLoading = true;

        const checkoutResponse = await apiInstance.post(
          "/subscription/checkout",
          {
            tiers: tiers,
            success_url: `${
              import.meta.env.VITE_WEB_URL
            }/pages/pricing/callback`,
            cancel_url: `${
              import.meta.env.VITE_WEB_URL
            }/onboarding?cancel=true`,
          }
        );

        console.log(
          "🚀 ~ subscribeToTier ~ checkoutResponse:",
          checkoutResponse
        );

        // If response contains a URL, it's a new subscription
        // If response contains subscription_id, it's an update
        return {
          url: checkoutResponse.data.url,
          subscriptionId: checkoutResponse.data.subscription,
        };
      } catch (error) {
        if (
          error.response?.status === 400 &&
          error.response?.data?.message === "Subscription already exists"
        ) {
          throw new Error("You already have an active subscription");
        }
        throw error;
      } finally {
        this.isSubscriptionRequestLoading = false;
      }
    },
    async regenerateTokenByApiKey(apiKey) {
      try {
        const response = await apiInstance.post(
          "/api/regenerate-token-by-key",
          {
            api_key: apiKey,
          }
        );

        // Check if we have a valid token in the response
        const hasuraAuthToken = response.headers?.["st-access-token"];

        console.log("hasuraAuthToken", hasuraAuthToken);

        if (!hasuraAuthToken) {
          throw new Error("No valid token received from server");
        }

        this.setAuthToken(hasuraAuthToken);

        const [userData, companyData, agencyData] = await Promise.all([
          this.getUserById(this.user?.id),
          this.getCompany(this.company?.id),
          this.getAgencyByClientId(this.company?.id),
        ]);

        this.user = userData.data?.users_by_pk;
        this.company = companyData.data?.companies_by_pk;
        localStorage.setItem(
          LOCALSTORAGE.COMPANY_TYPE,
          this.company?.company_type
        );
        this.currentAgency = agencyData.data?.agency_clients[0]?.agency || {};

        return response;
      } catch (error) {
        console.error("Failed to regenerate token by API key:", error);
        throw error;
      }
    },
    async getPresignedProfilePictureUrl(url) {
      try {
        // If we already have a presigned URL for the current profile picture, return it
        if (this.presignedProfilePictureUrl && url) {
          return this.presignedProfilePictureUrl;
        }

        // If no URL is provided, return null
        if (!url) {
          return null;
        }

        const data = await apiInstance.post(`/generate-presigned-url`, {
          s3Url: url,
        });

        console.log("data", data.data.presignedUrl);

        this.presignedProfilePictureUrl = data.data.presignedUrl;

        return this.presignedProfilePictureUrl;
      } catch (error) {
        console.error(
          "Error generating presigned URL for profile picture:",
          error
        );

        return null;
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
    getSignupResponse: (state) => state.signup.response,
    getSigninResponse: (state) => state.signin.response,
    isSignupLoading: (state) => state.signup.isLoading,
    isSigninLoading: (state) => state.signin.isLoading,
    getAgencies: (state) => state.company.clients,
    companySubscription: (state) =>
      state.company?.subscription_transactions?.[0],

    companySubscriptionTiers: (state) => {
      const activeTransaction = state.company?.subscription_transactions?.[0];

      if (!activeTransaction) return [];

      return activeTransaction.subscription_transaction_tiers;
    },

    companySubscriptionTiersByKeys: (state) => {
      const activeTransaction = state.company?.subscription_transactions?.[0];

      if (!activeTransaction) return [];

      return activeTransaction.subscription_transaction_tiers.map((tier) => {
        return tier?.subscription_tier?.key;
      });
    },
    leftoverSubscriptionDays: (state) => {
      const endDate = new Date(
        state.company?.subscription_transactions?.[0]?.end_date
      );

      if (!endDate) return 0;
      const currentDate = new Date();
      const diffTime = endDate - currentDate;

      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
  },
});
