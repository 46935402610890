<!-- Dashboard Page Component -->

<script setup>
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import BaseDashboard from "@/@core/components/dashboard/BaseDashboard.vue";
import NavBar from "@/@core/components/NavBar.vue";

import { useDashboardStore } from "@/composables/store/useDashboardStore";
import { useReportStore } from "@/composables/store/useReportStore";

import IndiaEcommerceDashboard from "@/@core/components/dashboard/IndiaEcommerceDashboard.vue";
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useErrorStore } from "@/composables/store/useErrorStore";
import { useSuccessStore } from "@/composables/store/useSuccessStore";
import amazonEcommerce from "@/utils/dashboard/amazon_ecommerce";
import blinkit from "@/utils/dashboard/blinkit";
import blinkitBudgetTracker from "@/utils/dashboard/blinkit_tracker";
import flipkartGrocery from "@/utils/dashboard/flipkart_grocery";
import flipkartNational from "@/utils/dashboard/flipkart_national";
import googleAds from "@/utils/dashboard/google_ads";
import googleAdsPerformance1 from "@/utils/dashboard/google_ads_performance_1";
import googleAdsPerformance2 from "@/utils/dashboard/google_ads_performance_2";
import googleAnalytics from "@/utils/dashboard/google_analytics";
import indiaEcommerce from "@/utils/dashboard/india_ecommerce";
import instacart from "@/utils/dashboard/instacart";
import metaAds from "@/utils/dashboard/meta_ads";
import myntra from "@/utils/dashboard/myntra";
import oneMG from "@/utils/dashboard/onemg";
import zepto from "@/utils/dashboard/zepto";
import zepto2 from "@/utils/dashboard/zepto2";

definePage({
  meta: {
    layout: "blank",
  },
});

const dashboardMapper = {
  google_analytics_1: googleAnalytics,
  google_ads_1: googleAds,
  meta_ads_1: metaAds,
  google_ads_performance_1: googleAdsPerformance1,
  google_ads_performance_2: googleAdsPerformance2,
  amazon_ecommerce_1: amazonEcommerce,
  india_ecommerce_1: indiaEcommerce,
  blinkit_1: blinkit,
  zepto_1: zepto,
  zepto_2: zepto2,
  flipkart_national_1: flipkartNational,
  flipkart_grocery_1: flipkartGrocery,
  instacart_1: instacart,
  blinkit_budget_tracker: blinkitBudgetTracker,
  myntra_1: myntra,
  onemg_1: oneMG,
};

const dashboardStore = useDashboardStore();
const successStore = useSuccessStore();
const errorStore = useErrorStore();
const { selectedDashboard, baseCardConfig } = storeToRefs(dashboardStore);
const reportStore = useReportStore();
const route = useRoute();
const companyId = route.query.company_id;
const saveFilterDialog = ref(false);
const isSavingFilter = ref(false);
const menuOpen = ref(false);

const filterForm = ref({
  name: "",
});

// Default date range (7 days ago to today)
const dateRange = ref([
  new Date(new Date().setDate(new Date().getDate() - 7)), // 7 days ago
  new Date(), // Today
]);

const companyName = computed(
  () => dashboardStore?.selectedCompany?.name || "Sample Company"
);

// Watch for date range changes
watch(dateRange, (value) => {
  const startDate = value[0].toISOString().split("T")[0]; // Format start date as YYYY-MM-DD
  const endDate = value[1].toISOString().split("T")[0]; // Format end date as YYYY-MM-DD

  // Update dashboardStore's filters and individual date properties
  dashboardStore.startDate = startDate;
  dashboardStore.endDate = endDate;
});

// Store instances
const isLoading = ref(false);

const formatDateRange = (range) => {
  if (!range || range.length !== 2) return "";
  const start = range[0].toISOString().split("T")[0];
  const end = range[1].toISOString().split("T")[0];

  return `${start} to ${end}`;
};

const selectedReportKey = computed(() => {
  // First check URL query parameter
  const urlDashboardType = route.query.dashboard_type;
  if (urlDashboardType) return urlDashboardType;

  // Fallback to existing logic
  return selectedDashboard.value?.dashboard?.key || null;
});

// Watch dashboardStore.selectedDashboard.dashboard.key
watch(
  () => dashboardStore.selectedDashboard?.dashboard?.key,
  async (newKey) => {
    if (newKey) {
      console.log("newKey", newKey);
      console.log("isTemplate query", route.query.isTemplate);

      dashboardStore.hasFetchedData = false;
      dashboardStore.cardRefs = new Map();
      dashboardStore.baseCardConfig = dashboardMapper[newKey];
      await dashboardStore.initializeCards(dashboardStore.baseCardConfig);
      if (isPrintableMode.value) {
        await dashboardStore.generateReport({ hasAi: false });

        return;
      }

      if (isTemplateMode.value) {
        await dashboardStore.generateReport({
          hasAi: false,
          useFakeData: true,
        });
      }
    }
  }
);

const selectedReportName = computed(() => {
  // First check URL query parameter
  const dashboardType = route.query.dashboard_type;
  if (dashboardType) {
    const dashboard =
      reportStore.companyDashboards?.[0]?.company_dashboards.find(
        (dashboard) => dashboard.dashboard.key === dashboardType
      );

    return dashboard?.dashboard?.name || null;
  }

  // Fallback to existing logic
  return selectedDashboard.value?.dashboard?.name || null;
});

const authStore = useAuthStore();

// Add new refs for access control
const isEditingDisabled = ref(false);
const accessType = ref("full");

// Add new ref for tracking active filter
const activeFilter = ref(null);

// Update the applyQuickViewFilter function
const applyQuickViewFilter = async (filter) => {
  console.log("applyQuickViewFilter", filter);
  try {
    const metadata = await dashboardStore.applySavedFilter(filter);

    console.log("metadata", metadata);

    if (metadata.dashboard_type) {
      const matchingDashboard = dashboardStore.companyDashboards?.find(
        (dashboard) => dashboard.dashboard.key === metadata.dashboard_type
      );

      if (matchingDashboard) {
        dashboardStore.selectedDashboard = matchingDashboard;
        dashboardStore.usedFilters = { ...metadata.filters };
      }
    }

    dateRange.value = [
      new Date(metadata.dateRange.startDate),
      new Date(metadata.dateRange.endDate),
    ];

    // Update filters using usedFilters

    // Update UI elements based on filters
    if (metadata.filters.property_id) {
      const account = filteredGoogleAnalyticsIds.value.find((acc) =>
        acc.propertyIds?.includes(metadata.filters.property_id)
      );

      if (account) {
        dashboardStore.usedFilters.account_id = account.id;
        dashboardStore.selectedAccountPropertyIds = account.propertyIds || [];

        // selectedAccountPropertyIds.value = account.propertyIds || [];
      }
    } else {
      dashboardStore.selectedAccountId = null;
      dashboardStore.selectedAccountPropertyIds = [];
    }

    activeFilter.value = filter;
    await dashboardStore.initializeCards(dashboardStore.baseCardConfig);
    await dashboardStore.generateReport({ hasAi: false });

    successStore.setSuccessMessage("Filter applied successfully");
  } catch (error) {
    errorStore.setErrorMessage("Failed to apply filter");
    console.error("Error applying filter:", error);
  }
};

// First, update the computed properties for filtered accounts
const filteredGoogleAnalyticsIds = computed(() => {
  return (
    dashboardStore.connectedAccounts?.google_analytics?.map((account) => ({
      id: account.id,
      name: account.name,
      propertyIds: account.propertyIds,
    })) || []
  );
});

// Add new state to store connected accounts
const init = async () => {
  const {
    api_key,
    dashboard_type,
    start_date,
    end_date,
    filters,
    access_type,
    isTemplate,
  } = route.query;

  if (authStore.user?.user_type === "client_service") {
    localStorage.setItem("service_module", "dashboard");
  }

  if (
    authStore.company.profile_picture_url &&
    !authStore.presignedProfilePictureUrl
  ) {
    authStore.getPresignedProfilePictureUrl(
      authStore.company.profile_picture_url
    );
  }

  if (isTemplate === "true") {
    console.log("isTemplate", isTemplate);
    dashboardStore.hasFetchedData = true;
    await reportStore.getAllSystemDashboards();

    const dashboard = reportStore.dashboards.find(
      (dashboard) => dashboard.key === dashboard_type
    );

    dashboardStore.selectedDashboard = {
      dashboard: dashboard,
    };

    // dashboardStore.baseCardConfig = dashboardMapper[dashboard_type];
    // await dashboardStore.initializeCards(dashboardStore.baseCardConfig);

    return; // Exit early since we don't need to process company-specific logic
  }

  // Handle API key authentication if present
  if (api_key) {
    try {
      accessType.value = access_type || "full";
      dashboardStore.isEditingDisabled = access_type === "limited";
    } catch (error) {
      console.error("Error authenticating with API key:", error);
      errorStore.setErrorMessage("Invalid API key or authentication failed");
    }
  }

  if (start_date && end_date) {
    dateRange.value = [new Date(start_date), new Date(end_date)];
    dashboardStore.startDate = start_date;
    dashboardStore.endDate = end_date;
  } else {
    dateRange.value = [
      new Date(new Date().setDate(new Date().getDate() - 7)), // 7 days ago
      new Date(), // Today
    ];
    dashboardStore.startDate = dateRange.value[0].toISOString().split("T")[0];
    dashboardStore.endDate = dateRange.value[1].toISOString().split("T")[0];
  }

  if (route.query.company_id) {
    await reportStore.getAllSystemDashboards();
    await reportStore.getCompaniesWithDashboardsByClient(
      route.query.company_id
    );
    await dashboardStore.getSelectedCompanyDashboards(route.query.company_id);
    dashboardStore.connectedAccounts =
      await dashboardStore.getConnectedAccounts(route.query.company_id);

    if (dashboard_type) {
      dashboardStore.filters.dashboardType = dashboard_type;
      if (reportStore.companyDashboards?.[0]?.company_dashboards?.length > 0) {
        selectedDashboard.value =
          reportStore.companyDashboards?.[0]?.company_dashboards.find(
            (dashboard) => dashboard.dashboard.key === dashboard_type
          );
      }
    }

    if (filters) {
      try {
        console.log("filters", filters);

        const parsedFilters = JSON.parse(decodeURIComponent(filters));

        // Loop through the parsed filters and apply them to the dashboardStore
        Object.keys(parsedFilters).forEach((key) => {
          dashboardStore.usedFilters[key] = parsedFilters[key];
        });

        console.log("parsed filters", parsedFilters);
        if (parsedFilters.property_id) {
          const account = filteredGoogleAnalyticsIds.value.find((acc) =>
            acc.propertyIds?.includes(parsedFilters.property_id)
          );

          console.log("account", account);

          if (account) {
            dashboardStore.usedFilters.account_id = account.id;
            dashboardStore.selectedAccountPropertyIds =
              account.propertyIds || [];

            // selectedAccountId.value = account.id;
            // selectedAccountPropertyIds.value = account.propertyIds || [];
          }
        }
      } catch (parseError) {
        console.error("Error parsing filters for property_id:", parseError);
      }
    }

    await dashboardStore.getSavedFiltersByCompany(route.query.company_id);
    await dashboardStore.initializeCards(dashboardStore.baseCardConfig);
    await dashboardStore.generateReport({ hasAi: false });
  }
};

// Filtered platforms for conditional rendering
const filteredPlatforms = computed(() => {
  return selectedDashboard.value
    ? selectedDashboard.value.dashboard?.platforms || []
    : [];
});

// Watch for changes in the selected report key and update baseCardConfig with loading state
// watch(selectedReportKey, async (newValue) => {
//   isLoading.value = true;
//   await new Promise((resolve) => setTimeout(resolve, 500));
//   if (newValue) {
//     dashboardStore.baseCardConfig = dashboardMapper[newValue];
//   }
//   isLoading.value = false;
// });

const isPrinting = ref(false);

// Add these new refs and functions
const isGeneratingLink = ref(false);
const shareDialog = ref(false);
const generatedLink = ref("");
const selectedAccessType = ref("full");

// Add a watch for selectedAccessType
watch(selectedAccessType, (newAccessType) => {
  if (generatedLink.value) {
    const url = new URL(generatedLink.value);

    url.searchParams.set("access_type", newAccessType);
    generatedLink.value = url.toString();
  }
});

watch(
  () => dashboardStore.saveFilterDialog,
  (newValue) => {
    if (!newValue) {
      filterForm.value.name = "";
      dashboardStore.filterForm = {
        name: "",
      };
    }
  }
);

// Initialize the component
init();

// Add a new ref for forcing dashboard content refresh
const refreshKey = ref(0);

// Add this computed property in the <script setup> section
const isPrintableMode = computed(() => {
  return route.query.printable === "true";
});

const isTemplateMode = computed(() => {
  return route.query.isTemplate === "true";
});

const fallbackCopyToClipboard = (text) => {
  const textArea = document.createElement("textarea");

  textArea.value = text;
  textArea.style.position = "fixed"; // Avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
    successStore.setSuccessMessage("Link copied to clipboard");
  } catch (err) {
    errorStore.setErrorMessage("Failed to copy link");
  }

  document.body.removeChild(textArea);
};

const hasLimitedAccess = computed(() => {
  console.log("hasLimitedAccess", route.query.access_type);

  return route.query.access_type === "limited";
});
</script>

<template>
  <div
    id="center-div"
    data-test-id="dashboard-container"
    class="rounded-2xl bg-white flex flex-col h-screen"
  >
    <!-- Navbar -->
    <NavBar data-test-id="nav-bar" />

    <!-- Main Content -->
    <Transition name="fade" mode="out-in">
      <div class="flex flex-grow px-40">
        <!-- Left Column -->
        <div class="flex-grow bg-gray-100 overflow-auto">
          <div class="px-10 py-4 h-full bg-white">
            <div
              class="flex items-center justify-between"
              :class="{ 'printable-mode': isPrintableMode }"
            >
              <div class="flex flex-col gap-1">
                <h2 class="text-gray-900 font-semibold text-2xl">
                  {{ companyName }}
                </h2>
              </div>

              <!-- Conditional rendering based on companyId -->
              <div v-if="companyId" class="flex items-center gap-4">
                <!-- Add Quick View Filters Menu -->
                <VMenu v-model="menuOpen">
                  <template #activator="{ props }">
                    <VBtn
                      v-if="
                        dashboardStore.savedFilters.length && !hasLimitedAccess
                      "
                      color="primary"
                      variant="outlined"
                      v-bind="props"
                    >
                      Saved Reports
                      <VIcon end icon="tabler-chevron-down" />
                    </VBtn>
                  </template>

                  <VList>
                    <VListItem
                      v-for="filter in dashboardStore.savedFilters"
                      :key="filter.filter_pk"
                      :value="filter.filter_pk"
                      @click="applyQuickViewFilter(filter)"
                    >
                      <VListItemTitle class="d-flex align-center gap-2">
                        {{ filter.name }}
                        <VChip size="small" color="primary" variant="outlined">
                          {{ filter.dashboard?.name }}
                        </VChip>
                      </VListItemTitle>
                      <VListItemSubtitle class="mt-1">
                        {{
                          formatDateRange([
                            new Date(filter.metadata.dateRange.startDate),
                            new Date(filter.metadata.dateRange.endDate),
                          ])
                        }}
                      </VListItemSubtitle>
                    </VListItem>
                  </VList>
                </VMenu>

                <!-- Autocomplete for selecting Report Type -->
                <VAutocomplete
                  v-model="selectedDashboard"
                  class="bg-white"
                  style="inline-size: 240px"
                  :items="
                    reportStore.companyDashboards?.[0]?.company_dashboards
                  "
                  return-object
                  item-title="dashboard.name"
                  item-value="dashboard.key"
                  placeholder="Select Report Type"
                  :disabled="
                    isEditingDisabled ||
                    (route.query.dashboard_type && !route.query.company_id) ||
                    hasLimitedAccess
                  "
                  outlined
                  clearable
                />
              </div>
            </div>

            <div
              v-if="
                !selectedDashboard &&
                companyId &&
                selectedReportKey !== 'india_ecommerce_1'
              "
              class="flex justify-center mt-10 w-full h-1/2 bg-white items-center"
            >
              <p class="text-gray-500 text-lg">
                Please select a report type to view the dashboard.
              </p>
            </div>

            <div v-else-if="isLoading" class="flex justify-center mt-10">
              <!-- Loading spinner -->
              <VProgressCircular color="primary" indeterminate />
            </div>

            <div v-else class="mt-4">
              <!-- Dashboard Content - Wrap in Transition to handle reloading -->
              <Transition name="fade" mode="out-in">
                <div id="dashboard-content" :key="refreshKey" class="mt-5">
                  <IndiaEcommerceDashboard
                    v-if="selectedReportKey === 'india_ecommerce_1'"
                    :date-range="dateRange"
                    :company-name="companyName"
                  />
                  <BaseDashboard
                    v-else
                    :date-range="dateRange"
                    :filtered-platforms="filteredPlatforms"
                    :is-editing-disabled="isEditingDisabled"
                    :card-config="dashboardStore.baseCardConfig"
                    :fake-data="!companyId"
                    :show-logo="showLogo"
                    :logo-url="logoUrl"
                    :logo-position="logoPosition"
                    :logo-size="logoSize"
                    :include-logo-in-export="includeLogoInExport"
                    :report-name="selectedReportName"
                    :customer-name="dashboardStore.customer_id"
                    :account-name="
                      dashboardStore.account_id || dashboardStore.property_id
                    "
                  />
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style>
/* Print styles */
@media print {
  #center-div {
    overflow: visible !important;
    block-size: auto !important;
  }

  .no-print {
    display: none !important;
  }

  #dashboard-content {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}

/* Add smooth transitions for dialogs */
.v-dialog-transition-enter-active,
.v-dialog-transition-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.v-dialog-transition-enter-from,
.v-dialog-transition-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

/* Add hover effects for menu items */
:deep(.v-list-item) {
  transition: all 0.2s ease;
}

:deep(.v-list-item:hover) {
  background-color: rgba(var(--v-theme-primary), 0.05);
  transform: translateX(4px);
}

/* Smooth alert transitions */
:deep(.v-alert) {
  transition: all 0.3s ease;
}

:deep(.v-alert:hover) {
  box-shadow: 0 2px 8px rgba(var(--v-theme-on-surface), 0.1);
  transform: translateY(-1px);
}

/* Add subtle hover effect for filter chips */
:deep(.v-chip) {
  transition: all 0.2s ease;
}

:deep(.v-chip:hover) {
  box-shadow: 0 2px 4px rgba(var(--v-theme-on-surface), 0.1);
  transform: translateY(-1px);
}

/* Smooth button transitions */
.v-btn {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.v-btn:not(:disabled):hover {
  box-shadow: 0 4px 8px rgba(var(--v-theme-on-surface), 0.15);
  transform: translateY(-1px);
}

.v-btn:not(:disabled):active {
  transform: translateY(0);
}

/* Add fade transition for content changes */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Add styles to ensure smooth transitions */
.filters-section {
  position: relative;
  z-index: 2;
}

#dashboard-content {
  position: relative;
  z-index: 1;
}

/* Smooth fade transition for dashboard content */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Hide printable-hidden elements when printable=true URL param is present */
.printable-mode {
  display: none !important;
}
</style>
